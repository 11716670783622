import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row } from '../components/shards-react/components';
import { DefaultLayout } from '../layouts';

const Documentation = () => (
  <DefaultLayout onSearchInputChange={() => {}}>
    <Container fluid className="main-content-container px-4 pb-4">

      <Row noGutters className="page-header py-5" />

      <div className="documentation">
        <div className="documentation_content" />
        {'\u2022 '}
        See
        <span>
          <NavLink to="/terms" className="termsLink"> Terms & Conditions</NavLink>
        </span>
      </div>
    </Container>
  </DefaultLayout>
);

export default Documentation;

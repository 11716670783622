import React from 'react';
import { Nav } from '../../../shards-react/components';

import NavbarToggle from '../NavbarToggle';

export default () => (
  <Nav navbar className="border-left flex-row">
    <NavbarToggle />
  </Nav>
);

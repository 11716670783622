import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col } from '../shards-react/components';
// import { fontSize } from "@mui/material/node_modules/@mui/system";

const PageTitle = ({
  title, subtitle, className, ...attrs
}) => {
  const classes = classNames(
    className,
    'text-left',
    'text-md-left',
    'mb-sm-0'
  );

  return (
    <Col xs="12" sm="4" className={classes} {...attrs}>
      <span className="text-uppercase page-subtitle p-1" style={{ color: '#fe5000', fontSize: '1rem' }}>{subtitle}</span>
      <h3 className="page-title">{title}</h3>
    </Col>
  );
};

PageTitle.propTypes = {

  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,

  /**
   * The page title.
   */
  title: PropTypes.string,
};

export default PageTitle;

export const TIMEOUT = {
  COLLAPSE: 350,
  FADE: 150,
  HIDE: 0,
  SHOW: 0,
};

export const EVENTS = {
  CLICK: ['click', 'touchstart', 'keyup'],
  FOCUS: ['focusin', 'focusout'],
  MOUSE: ['mouseenter', 'mouseleave'],
};

export const KEYCODES = {
  DOWN: 40,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  TAB: 9,
  UP: 38,
};

export const TRANSITION_KEYS = [
  'in',
  'mountOnEnter',
  'unmountOnExit',
  'appear',
  'enter',
  'exit',
  'timeout',
  'onEnter',
  'onEntering',
  'onEntered',
  'onExit',
  'onExiting',
  'onExited',
];

export const TRANSITION_STATUS = {
  ENTERED: 'entered',
  ENTERING: 'entering',
  EXITED: 'exited',
  EXITING: 'exiting',
};

export const TRANSITION_CLASS_MAP = {
  [TRANSITION_STATUS.ENTERING]: 'collapsing',
  [TRANSITION_STATUS.ENTERED]: 'collapse show',
  [TRANSITION_STATUS.EXITING]: 'collapsing',
  [TRANSITION_STATUS.EXITED]: 'collapse',
};

export const POPPER_PLACEMENTS = [
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'bottom-end',
  'bottom',
  'bottom-start',
  'left-end',
  'left',
  'left-start',
  'auto-start',
  'auto',
  'auto-end',
];

export const DROPDOWN_POSITION_MAP = {
  DOWN: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  UP: 'top',
};

export const BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl'];

/**
 * FORMS
 */

export const INPUT_TYPES = [
  'text',
  'password',
  'email',
  'number',
  'tel',
  'url',
  'search',
  'range',
  'color',
  'date',
  'time',
  'datetime',
  'datetime-local',
  'month',
  'week',
  'file',
];

export const INPUT_GROUP_ADDON_TYPES = ['prepend', 'append'];

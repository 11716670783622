/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import '../styles/signin.css';
import { NavLink } from 'react-router-dom';
import Loading from '../components/Loading';
import * as Helpers from '../helpers';

class Signin extends Component {
  state = {
    email: '',
    password: '',
  };

  componentDidMount() {
    Helpers.token.remove('application:token');
  }

  onChange = field => e => {
    this.setState({ [field]: e.target.value });
  };

  submit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    const { signin, load, history } = this.props;
    signin(email, password, () => {
      if (!Helpers.token.get('user:mode')) Helpers.token.set('developer', 'user:mode');
      history.push('/dashboard');
      load();
    });
  };

  render() {
    const { email, password } = this.state;
    const { admin: { loading } } = this.props;

    return (
      <div className="signinBody">
        <div className="signinBox">
          <h2>Signin to your account</h2>
          <h6 className="qq">
            Don't have any account?
          </h6>
          <NavLink className="signinNavLink" to="/signup" style={{ textDecoration: 'none' }}><h6 style={{ color: '#8add66' }}>Sign Up</h6></NavLink>

          <input type="email" placeholder="Email" value={email} onChange={this.onChange('email')} />
          <input type="password" placeholder="Password" value={password} onChange={this.onChange('password')} />
          <button type="submit" onClick={this.submit}>
            { loading.some(item => item === '/application/signin') ? <Loading width="25" size="small" /> : 'Signin' }
          </button>
          <div className="termsBox">
            <input type="checkbox" />
            <span>Keep me signed in</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;

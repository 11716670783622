/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Card,
  CardBody
  // Button,
  // ListGroup,
  // ListGroupItem
} from '../shards-react/components';

const UserDetails = ({ admin }) => (
  <Card small className="mb-4 pt-3">
    <CardBody className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <i className="fa fa-user fa-2x" aria-hidden="true" style={{ color: '#00ab84' }} />
      </div>
      <h5 className="mb-0">{admin.name}</h5>
      <h6>{admin.email}</h6>
      <h6>
        Application_id:
        {' '}
        {admin.application_id}
      </h6>
    </CardBody>
  </Card>
);

UserDetails.propTypes = {
  /**
   * The user details object.
   */
};

UserDetails.defaultProps = {};

export default UserDetails;

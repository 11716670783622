import axiosLib from 'axios';
import jwtDecode from 'jwt-decode';

import token from './token';

let axios = axiosLib;
const api = (url, method, data, { success, error }, actionTypes) => (dispatch, mockAxios) => {
  // eslint-disable-next-line no-console
  // console.log(`-- ${method} request made to ${url}`);

  const { responder, loading, error: errorConstant } = actionTypes;

  if (mockAxios.get) axios = mockAxios;

  dispatch({
    payload: url,
    type: loading,
  });
  axios.defaults.headers.common.Authorization = `Bearer ${token.get()}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const applicationStatus = token.get() ? jwtDecode(token.get()).status.toString() === 'true' ? 'true' : 'false' : 'null';
  const baseUrl = process.env.NODE_ENV === 'production' && token.get('user:mode') === 'developer' ? process.env.REACT_APP_BASE_URL.replace('api.', applicationStatus === 'true' || applicationStatus === 'null' ? 'api.' : 'staging.api.') : process.env.REACT_APP_BASE_URL;

  return axios[method.toLowerCase()](`${baseUrl}${url}`, data)
    .then(res => {
      dispatch({
        payload: url,
        type: loading,
      });
      dispatch({
        payload: res.data,
        type: responder,
      });

      if (typeof success === 'function') success(res.data, dispatch);

      return true;
    }).catch(e => {
      // eslint-disable-next-line no-console
      console.log('error ', e);
      dispatch({
        payload: url,
        type: loading,
      });
      dispatch({
        errorObj: e,
        payload: e.response && e.response.data,
        type: errorConstant,
      });

      if (typeof error === 'function') error(e.response && e.response.data, dispatch);

      if (e.response && e.response.status === 401) {
        token.remove();
        window.location = '/signin';
      }

      return false;
    });
};

export default api;

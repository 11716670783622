/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from '../../shards-react/components';

import { Dispatcher, Constants } from '../../../flux';
import carrotSvgOrange from '../../../assets/images/carrotlogoorange.svg';

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  }

  render() {
    const { hideLogoText } = this.props;
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
          type="light"
        >
          <NavbarBrand
            className="w-100 mr-0"
            href="dashboard"
            style={{ lineHeight: '25px', margin: 'auto', textAlign: 'center' }}
          >
            <div className="d-table m-auto">
              {/* {!hideLogoText && (
                <span className="d-none d-md-inline ml-1">
                  Carrot Credit
                </span>
              )} */}
              {!hideLogoText && (
                <span className="d-none d-md-inline ml-1">
                  <img src={carrotSvgOrange} alt="carrot-logo" width={86} />
                  <p className="inputSpecs">Developer Dashboard</p>
                </span>
              )}
            </div>
          </NavbarBrand>
          {/* eslint-disable-next-line */}
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={this.handleToggleSidebar}
          >
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
};

export default SidebarMainNavbar;

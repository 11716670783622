import React from 'react';
import { Link } from 'react-router-dom';
import './General.css';

class NotFound extends React.Component {
  render() {
    return (
      <div id="not-found">
        <h1>404</h1>
        <Link to="/signin" className="btn btn-lg btn-secondary">Go to Signin</Link>
      </div>
    );
  }
}

export default NotFound;

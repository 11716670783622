import React, { Component } from 'react';
import '../styles/signin.css';
import { NavLink } from 'react-router-dom';
import Loading from '../components/Loading';
import * as Helpers from '../helpers';

class Signup extends Component {
  state = {
    agreement: false,
    email: '',
    fullname: '',
    password: '',
  };

  componentDidMount() {
    Helpers.token.remove('application:token');
  }

  onChange = field => e => {
    this.setState({ [field]: e.target.value });
  };

  handleCheck = field => e => {
    this.setState({ [field]: e.target.checked });
  };

  submit = e => {
    e.preventDefault();
    const {
      fullname,
      email,
      password,
      agreement,
    } = this.state;
    const { load, history, signup } = this.props;
    if (agreement === true) {
      const name = fullname ? Helpers.capitalizeFirstLetter3(fullname) : fullname;
      setTimeout(() => {
        signup(name, email, password, res => {
          Helpers.notification.success(res.message);
          history.push('/confirmation');
          load();
        });
      }, 500);
    } else {
      Helpers.notification.error('Terms and Conditions Apply');
    }
  };

  render() {
    const { admin: { loading } } = this.props;
    const {
      fullname,
      email,
      password,
    } = this.state;

    return (
      <div className="signinBody">
        <div className="signinBox">
          <h1>Create Account</h1>
          <h6 className="qq">
            Already have an account?
          </h6>
          <NavLink to="/signin" style={{ textDecoration: 'none' }}><h6 style={{ color: '#8add66' }}>Signin</h6></NavLink>

          <input type="text" placeholder="Fullname" value={fullname} onChange={this.onChange('fullname')} />
          <input type="email" placeholder="Email" value={email} onChange={this.onChange('email')} />
          <input type="password" placeholder="Password" value={password} onChange={this.onChange('password')} />
          <p className="inputSpecs">
            Must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 symbol character
            <br />
            Must be a minimum of 8 characters long
          </p>
          <button type="submit" onClick={this.submit}>
            { loading.some(item => item === '/application/signup') ? <Loading width="25" size="small" /> : 'Sign up' }
          </button>
          <div className="termsBox">
            <input type="checkbox" onChange={this.handleCheck('agreement')} />
            <span>
              I have read and agree with the
              <NavLink to="/terms" className="termsLink"> terms & conditions</NavLink>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;

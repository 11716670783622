import React from 'react';

// Layout Types
import { DefaultLayout } from '../layouts';

// Route Views
import Dashboard from './Dashboard';
import ApplicationProfile from './ApplicationProfile';
import TableList from './TableList';
import Documentation from './Documentation';
import Signup from './Signup';
import Signin from './Signin';
import NotFound from './NotFound';
import Confirmation from './Confirmation';
import TandC from './TandC';
import Settings from './Settings';

const baseRoutes = {
  private: [
    {
      component: props => (
        <Dashboard {...props} />
      ),
      exact: true,
      layout: DefaultLayout,
      path: '/',
    },
    {
      component: props => (
        <Dashboard {...props} />
      ),
      exact: true,
      layout: DefaultLayout,
      path: '/dashboard',
    },
    {
      component: props => (
        <TableList {...props} type="portfolio" />
      ),
      exact: true,
      layout: DefaultLayout,
      path: '/portfolio',
    },
    {
      component: props => (
        <TableList {...props} type="user" />
      ),
      exact: true,
      layout: DefaultLayout,
      path: '/user',
    },
    {
      component: props => (
        <Documentation {...props} />
      ),
      exact: true,
      layout: DefaultLayout,
      path: '/documentation',
    },
    {
      component: props => (
        <TableList {...props} type="loan" />
      ),
      exact: true,
      layout: DefaultLayout,
      path: '/loan',
    },
    {
      component: props => (
        <ApplicationProfile {...props} />
      ),
      exact: true,
      layout: DefaultLayout,
      path: '/profile',
    },
    {
      component: props => (
        <Settings {...props} />
      ),
      exact: true,
      layout: DefaultLayout,
      path: '/settings',
    },
  ],
  public: [
    {
      component: Signup,
      exact: true,
      path: '/signup',
    },
    {
      component: Signin,
      exact: true,
      path: '/',
    },
    {
      component: Signin,
      exact: true,
      path: '/signin',
    },
    {
      component: NotFound,
      exact: true,
      path: '/404',
    },
    {
      component: Confirmation,
      exact: true,
      path: '/confirmation',
    },
    {
      component: TandC,
      exact: true,
      path: '/terms',
    },
  ],
};

export default baseRoutes;


/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BREAKPOINTS } from '../constants';
import { CustomPropTypes } from '../utils';

const makeColumnClass = (isXs, breakpoint, colSize) => {
  if (colSize === true || colSize === '') {
    return isXs ? 'col' : `col-${breakpoint}`;
  } if (colSize === 'auto') {
    return isXs ? 'col-auto' : `col-${breakpoint}-auto`;
  }

  return isXs ? `col-${colSize}` : `col-${breakpoint}-${colSize}`;
};

const Col = props => {
  const {
    className, breakpoints, tag: Tag, ...attrs
  } = props;

  const columnClasses = [];

  breakpoints.forEach((breakpoint, idx) => {
    const columnProp = props[breakpoint];

    delete attrs[breakpoint];

    if (!columnProp && columnProp !== '') {
      return;
    }

    const isXs = idx === 0;

    if (typeof columnProp !== 'object') {
      const colClass = makeColumnClass(isXs, breakpoint, columnProp);
      columnClasses.push(colClass);
      return;
    }

    const colSizeInterfix = isXs ? '-' : `-${breakpoint}-`;
    const colClass = makeColumnClass(isXs, breakpoint, columnProp.size);

    columnClasses.push(
      classNames({
        [colClass]: columnProp.size || columnProp.size === '',
        [`order${colSizeInterfix}${columnProp.order}`]:
          columnProp.order || columnProp.order === 0,
        [`offset${colSizeInterfix}${columnProp.offset}`]:
          columnProp.offset || columnProp.offset === 0,
      })
    );
  });

  if (!columnClasses.length) {
    columnClasses.push('col');
  }

  const classes = classNames(className, columnClasses);

  return <Tag {...attrs} className={classes} />;
};

Col.propTypes = {

  /**
   * The available breakpoints.
   */
  // eslint-disable-next-line react/forbid-prop-types
  breakpoints: PropTypes.array,

  /**
   * The class name.
   */
  className: PropTypes.string,

  /**
   * Col number or config object for lg viewports.
   */
  lg: CustomPropTypes.column,

  /**
   * Col number or config object for md viewports.
   */
  md: CustomPropTypes.column,

  /**
   * Col number or config object for sm viewports.
   */
  sm: CustomPropTypes.column,

  /**
   * The component tag type.
   */
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Col number or config object for xl viewports.
   */
  xl: CustomPropTypes.column,

  /**
   * Col number or config object for xs viewports.
   */
  xs: CustomPropTypes.column,
};

Col.defaultProps = {
  breakpoints: BREAKPOINTS,
  tag: 'div',
};

export default Col;

import React, { useState, useCallback } from 'react';
import {
  Container,
  Row,
  Col
} from '../components/shards-react/components';
import { DefaultLayout } from '../layouts';
import PageTitle from '../components/common/PageTitle';
import * as Helpers from '../helpers';
import './General.css';

const Settings = props => {
  const {
    updateApplication,
  } = props;

  const [data, setData] = useState({
    logo: '',
    token: '',
    url: '',
  });

  const handleChange = (field, newState = data) => e => {
    setData({
      ...newState,
      [field]: e.target.value,
    });
  };

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    setTimeout(() => {
      updateApplication(
        '',
        '',
        '',
        data.url,
        data.token,
        data.logo,
        res => {
          Helpers.notification.success(res.message);
        }
      );
    }, 500);
    setTimeout(() => {
      setData({
        logo: '',
        token: '',
        url: '',
      });
    }, 4000);
  }, [data, updateApplication]);

  const mode = Helpers.capitalizeFirstLetter(process.env.NODE_ENV);

  return (
    <DefaultLayout onSearchInputChange={() => {}}>
      <Container fluid className="main-content-container px-4">

        <Row noGutters className="page-header py-5" />

        <PageTitle subtitle="Settings" />
        <Row>
          <Col lg="6">
            <div className="settingsBox">
              <h5>{mode}</h5>
              <div>
                <div>
                  <input className="pbi" type="text" placeholder="BASE_URL" value={data.url} onChange={handleChange('url')} autoComplete="off" />
                  <p className="inputSpecs">
                    {mode}
                    {' '}
                    BASE_URL
                  </p>
                </div>
                <div>
                  <input className="pbi" type="text" placeholder="TOKEN" value={data.token} onChange={handleChange('token')} autoComplete="off" />
                  <p className="inputSpecs">
                    {mode}
                    {' '}
                    TOKEN
                  </p>
                </div>
                <div>
                  <input className="pbi" type="text" placeholder="LOGO URL" value={data.logo} onChange={handleChange('logo')} autoComplete="off" />
                  <p className="inputSpecs">
                    {mode}
                    {' '}
                    LOGO URL
                  </p>
                </div>
              </div>

              <button type="submit" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  );
};

export default Settings;

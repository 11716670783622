// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return [
    {
      htmlAfter: '',
      htmlBefore: '<i class="material-icons">dashboard</i>',
      title: 'Dashboard',
      to: '/dashboard',
    },
    {
      htmlBefore: '<i class="material-icons">people</i>',
      title: 'Users',
      to: '/user',
    },
    {
      htmlBefore: '<i class="material-icons">account_balance</i>',
      title: 'Loans',
      to: '/loan',
    },
    // {
    //   title: "Repayments",
    //   htmlBefore: '<i class="material-icons">payment</i>',
    //   to: "/repayment",
    // },
    {
      htmlBefore: '<i class="material-icons">account_box</i>',
      title: 'Portfolio',
      to: '/portfolio',
    },
    {
      htmlBefore: '<i class="material-icons">assignment</i>',
      title: 'Documentation',
      to: '/documentation',
    },
    {
      htmlBefore: '<i class="material-icons">lock</i>',
      title: 'Settings',
      to: '/settings',
    },
  ];
}

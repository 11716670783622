import React, { useState } from 'react';
import classNames from 'classnames';
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText
} from '../shards-react/components';

import '../../styles/range-date-picker.css';

const today = new Date();
const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
const yesterdayMonth = yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : `0${yesterday.getMonth() + 1}`;
const tomorrowMonth = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : `0${tomorrow.getMonth() + 1}`;
const yesterdayDay = yesterday.getDate() > 9 ? yesterday.getDate() : `0${yesterday.getDate()}`;
const tomorrowDay = tomorrow.getDate() > 9 ? tomorrow.getDate() : `0${tomorrow.getDate()}`;
const toDate = `${tomorrow.getFullYear()}/${tomorrowMonth}/${tomorrowDay}`;
const fromDate = `${yesterday.getFullYear()}/${yesterdayMonth}/${yesterdayDay}`;

const RangeDatePicker = props => {
  const { className, onDateChange } = props;
  const classes = classNames(className, 'd-flex', 'my-auto', 'date-range');

  const [to, setTo] = useState(new Date(toDate));
  const [from, setFrom] = useState(new Date(fromDate));

  const onChange = (which, value) => {
    const date = {
      from: fromDate,
      to: toDate,
    };
    if (which === to) {
      date.to = value;
    } else {
      date.from = value;
    }
    onDateChange(date);
  };

  const handleStartDateChange = newValue => {
    setFrom(newValue);
    const month = newValue.getMonth() + 1 > 9 ? newValue.getMonth() + 1 : `0${newValue.getMonth() + 1}`;
    const day = newValue.getDate() > 9 ? newValue.getDate() : `0${newValue.getDate()}`;
    const date = `${newValue.getFullYear()}/${month}/${day}`;
    onChange('from', date);
  };

  const handleEndDateChange = newValue => {
    setTo(newValue);
    const month = newValue.getMonth() + 1 > 9 ? newValue.getMonth() + 1 : `0${newValue.getMonth() + 1}`;
    const day = newValue.getDate() > 9 ? newValue.getDate() : `0${newValue.getDate()}`;
    const date = `${newValue.getFullYear()}/${month}/${day}`;
    onChange('to', date);
  };

  return (
    <InputGroup className={classes}>
      <InputGroupAddon type="append">
        <InputGroupText>
          <i className="material-icons" style={{ color: '#3d5170' }}>&#xE916;</i>
        </InputGroupText>
      </InputGroupAddon>

      <DatePicker
        size="sm"
        selected={from}
        onChange={handleStartDateChange}
        placeholderText="Start Date"
        dropdownMode="select"
        className="text-center"
      />

      <DatePicker
        size="sm"
        selected={to}
        onChange={handleEndDateChange}
        placeholderText="End Date"
        dropdownMode="select"
        className="text-center"
      />
    </InputGroup>
  );
};

export default RangeDatePicker;

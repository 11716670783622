import React, { useState, useCallback } from 'react';
import jwtDecode from 'jwt-decode';
import {
  Container,
  Row,
  Col
} from '../components/shards-react/components';
import UserDetails from '../components/user-profile-lite/UserDetails';
import { DefaultLayout } from '../layouts';
import * as Helpers from '../helpers';
import './General.css';

const ApplicationProfile = props => {
  const {
    updateApplication,
  } = props;

  const admin = jwtDecode(Helpers.token.get('application:token'));

  const [data, setData] = useState({
    confirmPassword: '',
    fullname: '',
    password: '',
  });

  const handleChange = (field, newState = data) => e => {
    setData({
      ...newState,
      [field]: e.target.value,
    });
  };

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    const name = data.fullname.length !== 0 ? Helpers.capitalizeFirstLetter3(data.fullname) : data.fullname;
    setTimeout(() => {
      updateApplication(
        name,
        data.password,
        data.confirmPassword,
        '',
        '',
        '',
        res => {
          Helpers.notification.success(res.message);
        }
      );
    }, 500);
    setTimeout(() => {
      setData({
        confirmPassword: '',
        fullname: '',
        password: '',
      });
    }, 4000);
  }, [data, updateApplication]);

  return (
    <DefaultLayout onSearchInputChange={() => {}}>
      <Container fluid className="main-content-container px-4">

        <Row noGutters className="page-header py-5" />

        <Row>
          <Col lg="4">
            <UserDetails admin={admin} />
          </Col>

          <Col lg="8">
            <div className="profileBox">
              <h5>Account details</h5>
              <input className="pbi" type="text" placeholder="Full Name" value={data.fullname} onChange={handleChange('fullname')} autoComplete="off" />
              <p className="inputSpecs">Fullname</p>
              <input className="pbi" type="password" placeholder="Password" value={data.password} onChange={handleChange('password')} autoComplete="off" />
              <p className="inputSpecs">
                Must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 symbol character
                <br />
                Must be a minimum of 8 characters long
              </p>
              <input className="pbi" type="password" placeholder="Confirm Password" value={data.confirmPassword} onChange={handleChange('confirmPassword')} autoComplete="off" />
              <button type="submit" onClick={handleSubmit}>
                Update Account
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  );
};

export default ApplicationProfile;

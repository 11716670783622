/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from '../../../shards-react/components';

import * as Helpers from '../../../../helpers';
import './UserActions.css';

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  logOut() {
    Helpers.token.remove('application:token');
  }

  render() {
    const admin = jwtDecode(Helpers.token.get('application:token'));

    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions} className="userActions">
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3" style={{ color: '#949494' }}>
          <i className="fa fa-user fa-lg avatar-icon" aria-hidden="true" style={{ color: '#00ab84' }} />
          <div style={{ color: '#949494' }} className="d-none d-md-inline-block">{admin.name}</div>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="/profile" style={{ textDecoration: 'none' }}>
            <i className="material-icons" style={{ color: '#949494' }}>&#xE7FD;</i>
            {' '}
            Profile
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/signin" className="text-danger" onClick={this.logOut}>
            <i className="material-icons text-danger">&#xE879;</i>
            {' '}
            Logout
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem className="dropdownCancel" onClick={() => this.setState({ visible: false })}>
            <i className="fas fa-times" style={{ color: '#fe5000' }} />
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

import React, {
  useState, useEffect, useCallback, useMemo
} from 'react';
import sha256 from 'sha256';
import Pagination from 'react-bootstrap-4-pagination';
import {
  Container, Row, Col, Card, CardHeader, CardBody, Button
} from '../components/shards-react/components';
import '../styles/CustomStyle.css';
import RangeDatePicker from '../components/common/RangeDatePicker';
import Loading from '../components/Loading';
import MuiButton from '../components/Button';
import * as Helpers from '../helpers';
import { DefaultLayout } from '../layouts';

import './General.css';

const today = new Date();
const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
const yesterdayMonth = yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : `0${yesterday.getMonth() + 1}`;
const tomorrowMonth = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : `0${tomorrow.getMonth() + 1}`;
const yesterdayDay = yesterday.getDate() > 9 ? yesterday.getDate() : `0${yesterday.getDate()}`;
const tomorrowDay = tomorrow.getDate() > 9 ? tomorrow.getDate() : `0${tomorrow.getDate()}`;
const toDate = `${tomorrow.getFullYear()}/${tomorrowMonth}/${tomorrowDay}`;
const fromDate = `${yesterday.getFullYear()}/${yesterdayMonth}/${yesterdayDay}`;

Helpers.token.set(toDate, 'date:to');
Helpers.token.set(fromDate, 'date:from');

const TableList = props => {
  const {
    getAll,
    clearAll,
    type,
    admin: { all, loading },
    refresh,
    // history,
  } = props;
  // const { location } = history;

  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const [actionID, setActionID] = useState(0);
  const [search, setSearch] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const loadingTable = loading.some(url => url
    === `/admin/${type}?limit=${rowsPerPage}&offset=${page}&from=${from}&to=${to}${
      search ? `&search=${search}` : ''
    }`);
  const loadingRefresh = loading.some(url => url === '/admin/portfolio');

  // const pathname = location.pathname.split('/')[1];
  const pathname = type;
  const searchKeys = useMemo(() => ({
    [`${pathname}_id`]: '',
    ...(pathname !== 'application' ? { user_id: '' } : {}),
    ...(
      pathname === 'user'
        ? {
          email: '', firstname: '', lastname: '', mobile: '',
        }
        : {}
    ),
  }), [pathname]);

  const getData = useCallback((clear = true) => {
    console.log('getData');
    if (isTyping) {
      return;
    }

    if (clear) {
      clearAll();
    }
    setTimeout(() => {
      getAll(
        type,
        rowsPerPage,
        page,
        search ? null : Helpers.token.get('date:from'),
        search ? null : Helpers.token.get('date:to'),
        search,
        () => {
          setIsTyping(false);
        }, () => {
          setIsTyping(false);
        }
      );
    }, 1000);
  }, [isTyping, type, clearAll, getAll, page, search]);

  useEffect(() => {
    getData();
  }, [getData]);

  const setDate = useCallback(date => {
    Helpers.token.set(date.to, 'date:to');
    setTo(date.to);
    Helpers.token.set(date.from, 'date:from');
    setFrom(date.from);
    getData();
  }, [getData]);

  const refreshPortfolio = useCallback(id => () => {
    setActionID(id);
    refresh(id, () => getData(false));
  }, [refresh, getData]);

  const handleSearch = useCallback(val => {
    if (!val || isTyping) {
      return;
    }

    const tempSearchValue = Object.keys(searchKeys).reduce((prev, current) => ({
      ...prev, [current]: val,
    }), {});
    setSearch(JSON.stringify(tempSearchValue));
    setIsTyping(true);
    getData(false);

    // eslint-disable-next-line no-console
    // console.log('Searching With:', searchValue);
  }, [getData, isTyping, searchKeys]);

  useEffect(() => {
    setTimeout(() => isTyping && setIsTyping(false), 5000);
  }, [isTyping]);

  const handleSearchInputChange = val => {
    handleSearch(val);
  };

  // eslint-disable-next-line no-unused-vars
  let options = [];
  // Set extra columns on the table as well as the search input (auto-complete) dropdown options
  if (type === 'portfolio') {
    options = all.map((item, i) => {
      const hash = sha256(item[`${type}_id`] || '').substring(0, 8);
      const label = `${item[`${type}_id`]} (${hash})`;
      all[i] = { [`${type} Hash ID`]: hash, ...item, refresh: true };
      return { ...all[i], label };
    });
  } else if (type === 'user') {
    options = all.map((item, i) => {
      const hash = sha256(item[`${type}_id`] || '').substring(0, 8);
      all[i] = { [`${type} Hash ID`]: hash, ...item };
      return { ...all[i], label: `${item.email} (${item.firstname} ${item.lastname})` };
    });
  } else {
    // eslint-disable-next-line no-unused-vars
    options = all.map((item, i) => {
      const hash = sha256(item[`${type}_id`] || '').substring(0, 8);
      const label = `${item[`${type}_id`]} (${hash})`;
      all[i] = { [`${type} Hash ID`]: hash, ...item };
      return { ...all[i], label };
    });
  }

  const paginationConfig = {
    currentPage: page + 1,
    onClick: newPage => {
      // console.log('pageChange: ', newPage);
      setPage(newPage - 1);
    },
    prevNext: true,
    showMax: 5,
    size: 'md',
    threeDots: true,
    totalPages: Math.ceil(all.length / rowsPerPage),
  };

  const keys = all.length === 0 ? [] : Object.keys(all[0]);

  return (
    <DefaultLayout {...props} onSearchInputChange={handleSearchInputChange}>
      <Container fluid className="main-content-container px-4 mb-3">

        <Row noGutters className="page-header py-5" />

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <p style={{ color: '#767676' }}>
                    {all.length === 0 ? 'No' : 'These are the'}
                    {' '}
                    {type}
                    s for the selected period
                    {' '}
                    <span><Button pill outline size="sm" className="mb-0 pt-1 pb-1" onClick={() => getData(false)}>Refresh</Button></span>
                  </p>
                </Row>
                <Row>
                  <RangeDatePicker onDateChange={setDate} />
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <table className="table mb-0">
                  <thead className="bg-light" style={{ color: '#3d5170' }}>
                    <tr>
                      {all.length === 0 || loadingTable ? null : keys.map((key, i) => {
                        const heading = key;

                        let showData;
                        if (type === 'user') {
                          showData = key === 'email' || key === 'firstname' || key === 'lastname' || key === 'date' || key === `${type} Hash ID`;
                          if (!showData) return null;
                        } else if (type === 'loan') {
                          showData = key === `application_${type}_id` || key === 'principal' || key === 'user_id' || key === 'date' || key === `${type} Hash ID`;

                          if (!showData) return null;
                        } else {
                          showData = key === 'application_user_id' || key === 'user-id' || key === 'amount' || key === 'refresh' || key === 'date' || key === `${type} Hash ID`;
                          if (!showData) return null;
                        }

                        return (
                          <th
                            key={`table-header-${i}`}
                            scope="col"
                            className="border-0"
                            style={{
                              color: '#767676', fontSize: '.75rem', padding: '20px', textAlign: 'center',
                            }}
                          >
                            {Helpers.capitalizeFirstLetter(heading)}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: '.8rem', textAlign: 'center' }}>
                    {all.length === 0 ? null
                      : loadingTable ? <Loading size="big" />
                        : all
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((item, ii) => (
                            <tr key={`table-row-${ii}`}>
                              {keys.map((key, iii) => {
                                const data = item[key];

                                let showData;
                                if (type === 'user') {
                                  showData = key === 'email' || key === 'firstname' || key === 'lastname' || key === 'date' || key === `${type} Hash ID`;
                                  if (!showData) return null;
                                } else if (type === 'loan') {
                                  showData = key === `application_${type}_id` || key === 'principal' || key === 'user_id' || key === 'date' || key === `${type} Hash ID`;

                                  if (!showData) return null;
                                } else {
                                  showData = key === 'application_user_id' || key === 'user-id' || key === 'amount' || key === 'refresh' || key === 'date' || key === `${type} Hash ID`;
                                  if (!showData) return null;
                                }

                                return (
                                  <td key={`table-data-${iii}`} title={typeof data === 'object' || typeof data === 'boolean' ? '' : data} style={{ color: '#767676', padding: '20px' }}>
                                    {
                                      (type === 'portfolio' && key === 'refresh')
                                        ? (type === 'portfolio' ? <MuiButton type="info" loading={(item.user_id === actionID && loadingRefresh)} onClick={refreshPortfolio(item.user_id)}>Refresh</MuiButton> : 'N/A')
                                        : data
                                    }
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                  </tbody>
                </table>
                {all.length === 0 ? null : (
                  <Row className="mx-3 pt-3 pb-1">
                    <Pagination {...paginationConfig} />
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  );
};

TableList.propTypes = {
  /**
   * The small stats dataset.
   */
  // smallStats: PropTypes.array,
};

TableList.defaultProps = {};

export default TableList;

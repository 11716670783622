import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './General.css';

class Confirmation extends React.Component {
  render() {
    return (
      <div id="confirmation">
        {/* <h6>Your information has been received successfully.</h6>
        <h6>Contact Carrot to activate your account</h6>
        <Link to="/signin" className="btn btn-lg btn-primary">Go to Signin</Link> */}

        <div className="card">
          <div className="card-header">
            <h3>Sign up successful</h3>
          </div>
          <div className="card-body">
            <ul>
              <li>
                Your information has been received successfully.
              </li>
              <li>
                Are you a Developer? Proceed to signin to test the Carrot developer dashboard.
              </li>
              <li>
                Are you a Carrot Partner? Contact Carrot to activate your account.
              </li>
            </ul>
            <Link to="/signin" className="btn btn-lg btn-secondary">Go to Signin</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Confirmation;

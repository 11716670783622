import jwtDecode from 'jwt-decode';

import * as Helpers from '../../helpers';

export const ERROR = 'ERROR';
export const LOADING = 'LOADING';
export const LOAD = 'LOAD';
export const SIGNIN = 'SIGNIN';
export const GET_ALL = 'GET_ALL';
export const REFRESH = 'REFRESH';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ALL = 'CLEAR_ALL';
export const SIGNUP = 'SIGNUP';
export const UPDATEAPPLICATION = 'UPDATEAPPLICATION';

export const load = () => dispatch => {
  const admin = jwtDecode(Helpers.token.get('application:token'));
  // eslint-disable-next-line no-console
  // console.log('loaded', admin);
  dispatch({ payload: admin, type: LOAD });
};

export const signin = (email, password, success) => Helpers.api(
  '/application/login',
  'POST',
  { email, password },
  { success },
  { error: ERROR, loading: LOADING, responder: SIGNIN }
);

export const clearAll = () => dispatch => {
  dispatch({ type: CLEAR_ALL });
};

export const getAll = (table, limit, offset, from, to, search, success, error) => Helpers.api(
  `/application/${table}?limit=${limit}&offset=${offset}
  ${
  from ? `&from=${from}` : ''
}${
  to ? `&to=${to}` : ''
}${
  search ? `&search=${search}` : ''
}`,
  'GET',
  { table },
  { error, success },
  { error: ERROR, loading: LOADING, responder: GET_ALL }
);

export const refresh = (user_id, success) => Helpers.api(
  '/application/portfolio',
  'PUT',
  { user_id },
  { success },
  { error: ERROR, loading: LOADING, responder: REFRESH }
);

export const signup = (name, email, password, success) => Helpers.api(
  '/application/signup',
  'POST',
  {
    email, name, password,
  },
  { success },
  { error: ERROR, loading: LOADING, responder: SIGNUP }
);

export const updateApplication = (name, password, confirmPassword, url, token, logo, success) => Helpers.api(
  '/application/update',
  'PUT',
  {
    confirmPassword, logo, name, password, token, url,
  },
  { success },
  { error: ERROR, loading: LOADING, responder: UPDATEAPPLICATION }
);

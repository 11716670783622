/* eslint-disable no-case-declarations */
import jwtDecode from 'jwt-decode';
import * as Helpers from '../../helpers';

import {
  ERROR,
  LOADING,
  LOAD,
  SIGNIN,
  CLEAR_ALL,
  GET_ALL,
  SIGNUP,
  UPDATEAPPLICATION
} from '../actions';

export const initialState = {
  admin_id: 0,
  all: [],
  application_id: 0,
  email: '',
  history: {
    loan: [], portfolio: [], repayment: [], user: [], withdrawal: [],
  },
  level: 0,
  loading: [],
  name: '',
  permissions: [],
  timestamp: '',
  token: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { loading } = state;
  const { payload } = action;
  switch (action.type) {
  case LOADING:
    return {
      ...state,
      loading: loading.some(item => item === payload)
        ? loading.filter(item => item !== payload)
        : [...loading, payload],
    };

  case ERROR:
    if (payload && payload.data && payload.data.error) {
      payload.data.error.map(err => Helpers.notification.error(err));
    } else if (payload && payload.message) {
      Helpers.notification.error(payload.message);
    } else {
      Helpers.notification.error('Unable to fetch some data');
    }
    return state;

  case LOAD:
    return {
      ...state,
      admin_id: payload.admin_id,
      email: payload.email,
      level: payload.level,
      name: payload.name,
      permissions: payload.permissions,
      timestamp: payload.timestamp,
    };

  case SIGNIN:
    Helpers.token.set(payload.data.token, 'application:token');
    const userEmail = jwtDecode(payload.data.token).email;
    if (!(Helpers.token.get('user:mode')) || Helpers.token.get('user:email') !== userEmail) {
      Helpers.token.set('developer', 'user:mode');
      Helpers.token.set(userEmail, 'user:email');
    }
    return {
      ...state,
      token: payload.data.token,
    };

  case CLEAR_ALL:
    return {
      ...state,
      all: [],
    };

  case GET_ALL:
    // eslint-disable-next-line no-console
    // console.log('load table payload', payload);
    return {
      ...state,
      all: payload.data.all,
    };

  case SIGNUP:
    return state;

  case UPDATEAPPLICATION:
    return state;

  default:
    return state;
  }
}

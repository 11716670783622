import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

export default function MuiButton(props) {
  const {
    onClick, loading, type = 'info', children,
  } = props;

  return (
    <LoadingButton
      variant="outlined"
      color={type}
      size="small"
      onClick={onClick}
      loading={loading}
      sx={{
        fontSize: '.65rem',
      }}
    >
      {children}
    </LoadingButton>
  );
}

import React from 'react';
import jwtDecode from 'jwt-decode';

import { Nav, Button } from '../../shards-react/components';

import SidebarNavItem from './SidebarNavItem';
import { Store } from '../../../flux';
import '../../../styles/CustomStyle.css';

import * as Helpers from '../../../helpers';

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: Helpers.token.get('user:mode') || 'developer',
      navItems: Store.getSidebarItems(),
    };

    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  handleMode = e => {
    const application = jwtDecode(Helpers.token.get('application:token'));
    const applicationStatus = application.status;
    const {
      mode,
      navItems,
    } = this.state;
    if (e.target.name === 'toggleMode' && applicationStatus) {
      this.setState({ mode: mode === 'developer' ? 'application' : 'developer', navItems });
      Helpers.token.set(mode === 'developer' ? 'application' : 'developer', 'user:mode');
      window.location.reload();
    } else {
      Helpers.notification.error('Account not verified. Cannot access Application');
    }
  }

  onChange() {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      navItems: Store.getSidebarItems(),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  logOut() {
    Helpers.token.remove('application:token');
  }

  render() {
    const { mode, navItems: items } = this.state;
    return (
      <div className="nav-wrapper" id="SideNavBars">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
        </Nav>

        <div className="devSwitchDiv">
          <Button pill outline size="sm" className="devSwitch" name="toggleMode" onClick={this.handleMode}>
            {Helpers.capitalizeFirstLetter3(mode)}
          </Button>
          <p className="inputSpecs">Click to toggle between Developer and Application mode</p>
        </div>

        <a href="/signin" className="LogoutBtn" onClick={this.logOut} style={{ backgroundColor: '#8add66' }}>
          <i className="material-icons">logout</i>
          <span>Logout</span>
        </a>
      </div>
    );
  }
}

export default SidebarNavItems;

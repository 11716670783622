/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './General.css';

function TandC() {
  return (
    <div id="TandC">
      <h1>Terms of Use</h1>
      <div className="text">
        <p>
          By accessing and using our Services (defined below), you are agreeing to these:
          {' '}
          <br />
          User Agreement, our Privacy Notice, and any applicable Additional Terms (defined below),
          ("collectively reffered to as the Terms/Agreement").
          {' '}
          <br />

          <b>
            It is important to read our entire Agreement carefully as some of the provisions
            affects your legal rights.
          </b>
        </p>

        <p>
          Subject to the conditions set forth herein, Carrot may, in its side discretion, amend
          this Agreement and the other Terms of Service at any time by posting a revised version
          on the Site.
          {' '}
          <br />

          Carrot will provide reasonable advance notice of any amendment that includes a substantial
          change, by posting and updated Terms of Service on the Site, providing notice on the Site,
          and/or sending you notice by email.
          {' '}
          <br />

          Any revisions to the Terms of Service will take effect on the noted effective date (each,
          as applicable, the
          {' '}
          <b>"Effective Date"</b>
          ).
        </p>

        <br />

        <h4><b>KEY TERMS</b></h4>
        <p>
          Save as otherwise set out in this Agreement
          <ul>
            <li>the services we provide are on an 'as is' basis</li>
            <li>
              we don't guarantee all of the information on our website, in our app, etc. is up to
              date or accurate at all times and we accept no liability for inaccuracies or omissions.
              We recommend you confirm the accuracy and completeness of any information before relying
              on it
            </li>
          </ul>
        </p>

        <h4><b>Who are we?</b></h4>
        <p>
          We are Carrot Digital Solutions Limited ("Carrot", "we", or "us"). Carrot is a financial services
          solution that enables users access credit using their digital assets as collateral.
          {' '}
          <br />

          This agreement remains in effect only as long as you seek to use the services offered by Carrot.
          You may discontinue this relationship at any time, without notice , at no cost or penalty to you.
          {' '}
          <br />

          These terms set out your agreement with us in relation to the use of our website, apps, and services, they
          do not create any relationship or agreement between you and any other person (including our affiliated
          and third party service providers).
        </p>

        <br />

        <p>
          <b>1. Our Services</b>
          {' '}
          <br />
          {' '}
          <br />

          When we refer to our "Services," we mean all products and services owned and operated by Carrot, Inc.
          or any of its affiliated companies (collectively, "Carrot," "we," or "us"), including the content, features,
          tools, data, software, and functions made available by Carrot through
          {' '}
          <a
            rel="noreferrer"
            href="https://www.carrotcredit.com/"
          >
            carrotcredit
          </a>
          {' '}
          our
          mobile applications, and other website or applications we operate, unless those websites or applications have
          posted separate or additional terms of services. As of the effective date of these terms, our Services include the following
          key features:

          <ul>
            <li><b>Access to Financial Product Offers.</b></li>
          </ul>
        </p>

        <br />

        <p>
          <b>2. Your Carrot Account</b>
          {' '}
          <br />
          {' '}
          <br />

          <b>2.1 General Requirement</b>
          {' '}
          <br />
          {' '}
          <br />

          By registering for an account to use the Site Services by using the Site or Site Services after the Effective
          Date if you had an account on the Effective Date, or by clicking to accept the Terms of Service/Use when
          prompted on the Site, you agree to abide by this Agreement and the other Terms of Service/Use.
          {' '}
          <br />

          You will need to register for a Carrot member account to access many aspects of our Services, personalized
          offers for products and Services. Registering for a member account is optional, but if you do not register
          for an account, you will not be able to access certain aspects of our Services.
          {' '}
          <br />

          By registering for a Carrot member account, you certify that:

          <ul>
            <li>You are 18 years of age or older</li>
            <li>
              Any information you provide to us, both when you register and in the future, is and will be true,
              accurate, current, and complete.
            </li>
            <li>You are only registering an account for yourself</li>
            <li>You will keep all information up-to-date</li>
          </ul>

          You must not sell, transfer, or assign your account to anyone else. You must keep your password confidential,
          you must not share it and you may not allow anyone else to log into Services as you. You are responsible for
          all activities that occur under your member account. If you believe that your account is no longer secure,
          notify us immediately via mail at
          {' '}
          <a href="mailto:support@carrotcredit.com">carrotcredit.</a>
        </p>

        <br />

        <p>
          <b>2.2 Specific Registration Consents</b>
          {' '}
          <br />
          {' '}
          <br />

          When you register as a member, you authorize us to create and maintain an account in your name using your
          account registration information and other information that may be collected about you in accordance with our
          Privacy Notice as part of providing our Services (collectively, such information is your "Member Profile.").
          By registering, you also specifically consent to let us request and receive your customer report data and
          other data about you from third parties to include in your Member Profile.
        </p>

        <p>
          <b>3. Limitations and Restriction on Use of Our Services</b>
          {' '}
          <br />
          {' '}
          <br />

          <b>3.1 Analysis and Estimates</b>
          {' '}
          <br />
          {' '}
          <br />

          As described in our Privacy Notice, we may use any available information we obtain to analyze, sort, and
          present certain information or features to you.
          {' '}
          <br />

          Any analysis and estimates we provide as part of our services are for illustrative and information purposes
          only. Our analysis and estimates are based on certain assumptions and use only the data we have. Our analysis
          and estimates are neither endorsed by, nor commissioned by, any of our partners. The Methodology we use to
          determine which offers, analysis, or other information is presented or highlighted is proprietary and we may
          elect to consider, ignore, emphasize, or de-emphasize certain factors atour sole and absolute discretion.
          {' '}
          <br />
          {' '}
          <br />

          <b>3.2 Third Party Offers</b>
          {' '}
          <br />
          {' '}
          <br />

          Concerning offers from our partners, we do not guarantee that you will presented with offers for any particular
          types of products or services or that you will meet the approval criteria for any particular offer.
          It is always your choice whether to apply for a product or service offered by our partners and we
          will never apply for a product or service to a partner on your behalf without your consent.
          {' '}
          <br />
          {' '}
          <br />

          <b>3.3 Not a Legal or Professional Advisor</b>
          {' '}
          <br />
          {' '}
          <br />

          Carrot is a credit repair organization, credit counsellor, or financial or legal advisor as defined
          under the laws of the Federal Republic of Nigeria. Use of our Services is not a replacement for personal,
          Professional advice or assistance regarding your finances, credit history, or fixing your credit rating.
          {' '}
          <br />
          {' '}
          <br />

          <b>3.4 Territorial Restrictions</b>
          {' '}
          <br />
          {' '}
          <br />

          We control and operate our Services from the Federal Republic of Nigeria and we make no reprensentations
          or warranties that the information, products, or services provided through our Services, or our Content (defined below),
          are appropriate for access or use in other jurisdictions. You are not permitted to access or use our Services in any jurisdiction
          or country if it would be contrary to the law or regulation of that jurisdiction or if it would subject us to the laws
          of, or any registration requirement with, such jurisdiction. We reserve the right to limit the availability of our Services or
          the provision of any of our Content to any person, geographic area, or jurisdiction, at any time and in our sole and
          absolute discretion.
          {' '}
          <br />
          {' '}
          <br />

          <b>3.5 Compliance with Law</b>
          {' '}
          <br />
          {' '}
          <br />

          You must comply with all applicable laws when using our Services. You are not permitted to use our Services to
          obtain any customer credit report, score, or other consumer information under false pretenses or in violation of
          applicable law.
          {' '}
          <br />
          {' '}
          <br />

          <b>3.6 Links to or Connections with Third Party Sites or Applications</b>
          {' '}
          <br />
          {' '}
          <br />

          Our Services or communications to you may contain third-party content or links to third-party sites,
          applications, or services (collectively, "Third-Party content"). Our Services may also include
          features that allow you to connect your Carrot account with accounts or services provided by the third parties,
          such as account you maintain with financial institutions or social media platforms like Facebook
          (collectively, "Third Party Services"). We do not control, maintain, or endorse the Third Party content or
          Third Party Services, including any damages , losses, failures, or problems caused by,
          related to, or arising from Third Party Content or Third Party Services. Your interactions
          and business dealings with the providers of the Third Party Content or Third Party Services, including products
          or services offered by such third parties, are solely between you and the third party. You should review all of
          the relevant terms and conditions associated with Third Party Content or Third Party Services, including any
          privacy policies and terms of service. We are not responsible for any information that you agree to share with
          third parties in connection with Third Party Content or Third Party Services.
        </p>

        <br />

        <p>
          <b>4. confidential Information</b>
          {' '}
          <br />
          {' '}
          <br />

          <b>4.1</b>
          {' '}
          You may agree to any terms they deem appropriate with respect to confidentiality.
          {' '}
          <br />
          {' '}
          <br />

          To the extent You provide confidential Information to the other, the recipient will protectg the secrecy
          of the discloser's confidential Information with the same degree of care as it uses to protect its confidential
          Information, but in no event with less than due care. On a User's written request, the party that recieved
          confidential Information will promptly destroy or return the disclosing party's confidential Information
          and any copies therof contained in or on its premises, systems, or any other equipment otherwise under
          its control.
        </p>

        <br />

        <p>
          <b>5. Monitoring of Our Services and Disclosure for Administrative and Legal Reasons</b>
          {' '}
          <br />
          {' '}
          <br />

          Carrot has the right, but not the obligation, to monitor our Services electronically. We may access, use,
          preserve, transfer or disclose, at any time and without notice to you, any information
          (including Personal Information as defined in our Privacy Notice) as reasonably necessary to (1) comply with
          any applicable law, regulation, subpoena, or legal process, or respond to any governmennt request or corporate
          with law enforcement if we believe such action is required or permitted by law; (2) enforce this Agreement,
          including to investigate potential violations; (3) protect the safety, integrity, rights, or security of our
          members, our Services or equipment, or any third party; (4) defect, prevent, or otherwise address fraud,
          security, or technical issues related to our Services or the services of our partners.
        </p>

        <br />

        <p>
          <b>6. Disclaimers</b>
          {' '}
          <br />
          {' '}
          <br />

          WE PROVIDE OUR SERVICES, OUR CONTENT, AND ALL CONTENT AND MATERIALS AVAILABLE THROUGH OUR SERVICES "AS IS" AND
          "AS AVAILABLE" WITHOUT ANY WARRANTY OR CONDITION OF ANY KIND, EXPRESS OR IMPLIED. CARROT, ON BEHALF OF ITSELF,
          ITS LICENSORS, DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO OUR SERVICES, OUR
          CONTENT, OR THE COMMUNITY CONTENT, INCLUIDNG THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, QUIET ENJOYMENT, QUALITY, ACCURACY, TITLE AND NON-INFRINGEMENT, AND ANY WARRANTY ARISING OUT OF COURSE
          OF DEALING, USAGE OR TRADE.
          {' '}
          <br />
          {' '}
          <br />

          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM OUR SERVICES, OUR CONTENT, THE COMMUNITY
          CONTENT, OR OTHERWISE AVAILABLE THROUGH OUR SERVICES WILL CREATE ANY WARRANTY REGARDING CARROT OR ANY OF OUR SERVICES
          THAT IS NOT EXPRESSLY STATED IN THESE TERMS. YOU USE OUR SERVICES, AND USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAINE
          OUR CONTENT, THE COMMUNITY CONTENT, OR OTHER CONTENT AVAILABLE THROUGH OUR SERVICES, AT YOUR DISCRETION AND RISK. YOU
          ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OR ACCESS TO OUR SERVICES, YOUR DEALING WITH ANY OTHER
          MEMBER OR THIRD PARTY, AND YOUR USE OF OUR CINTENT, THE COMMUNITY CONTENT, OR OTHER CONTENT AVAILABLE THROUGH OUR
          SERVICES. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING ANY COMPUTER SYSTEM OR MOBILE DEVICE
          USED IN CONNECTION WITH OUR SERVICES), OR THE LOSS OF DATA THAT MAY RESULT FROM THE USE OF OUR SERVICES OR THE USE OF
          ANY OF OUR CONTENT, THE COMMUNITY CONTENT OR OTHER CONTENT AVAILABLE THROUGH OUR SERVICES.
          {' '}
          <br />
          {' '}
          <br />

          YOU ACKNOWLEDGE THAT CARROT IS SOLELY AN INTERMEDIARY (I) BETWEEN YOU AND ANY CREDIT REPORTING AGENCY OR OTHER INFORMATION
          PROVIDERS SUPPLYING YOUR CREDIT REPORTS, CREDIT SCORES, AND OTHER INFORMATIONS; AND (II) BETWEEN YOU AND OUR PARTNERS
          WHOSE OFFERS WE PRESENT, CARROT EXPRESSLY DISCLAIMS ANY LIABILITY FOR ANY ERRORS OR OMISSIONS IN OUR CONTENT OR OUR SERVICES,
          INCLUDING THE INACCURACY OR INCOMPLETENESS OF ANY SUCH CREDIT REPORTS, CREDIT SCORES, OFFERS, OR OTHER INFORMATION, TOOLS,
          OR ANALYSES AVAILABLE THROUGH OUR SERVICES OR OUR CONTENT.
          {' '}
          <br />
          {' '}
          <br />

          NOTWITHSTANDING THE FOREGOING, NOTHING IN THIS SECTION SHALL AFFECT WARRANTIES THAT ARE INCAPABLE OF EXCLUSION OR RESTRICTION
          UNDER APPLICABLE LAW.
        </p>

        <br />

        <p>
          <b>8. Limitation of Liability</b>
          {' '}
          <br />
          {' '}
          <br />

          CARROT AND ITS REPRESENTATIVES, AGENTS, AND ANY OF THEIR RESPECTIVE OFFICERS, DIRSCTORS, EQUITY HOLDERS, EMPLOYEES, PARENT ENTITIES,
          SUBSIDIARIES, AFFILIATED ENTITIES, REPRESENTATIVES, AGENTS, AND LICENSORS (COLLECTIVELY. THE "CARROT PARTIES") WILL NOT BE LIABLE TO
          YOU OR ANY THIRD PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (SUCH AS LOSS OF PROFITS, LOSS OF
          GOODWILL, LOSS OF USE, LOSS OF DATA, BUSINESS INTERRUPTION, OR OTHER INTANGIBLE LOSSES) ARISING OUT OF OR RELATING IN ANY WAY TO OUR
          SERVICES, OUR CONTENT OR THE COMMUNITY CONTENT.
          {' '}
          <br />
          {' '}
          <br />

          EXCEPT AS EXPRESSLY PROVIDED IN SECTION 10 (DISPUTE RESOLUTION AND ARBITRATION) OR REQUIRED BY APPLICABLE LAW (E.G., ANY NON-WAIVABLE
          RIGHTS OR REMEDIES), IN NO EVENT WILL THE TOTAL LIABILITY OF THE CARROT PARTIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE
          USE OF, OR ANY INABILITY TO USE ANY PORTION OF, OUR SERVICES, OR OTHERWISE ARISING OUT OF OR RELATING TO THE AGREEMENT, WHETHER IN
          CONTRACT, TORT, OR OTHERWISE, EXCEED IN THE AGGREGATE ONE HUNDRED UNITED STATES DOLLARS ($100.00).
          {' '}
          <br />
          {' '}
          <br />

          IF ANY OF THE FOREGOING LIMITATIONS ARE FOUND TO BE INVALID, THE CARROT PARTIES' TOTAL LIABILITY FOR ALL DAMAGES, LOSSES, OR CAUSES OR
          ACTION OF ANY KIND OR NATURE WHATSOEVER SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW.
        </p>

        <br />

        <p>
          <b>8. Indemnification</b>
          {' '}
          <br />
          {' '}
          <br />

          You will defend (if requested by any Carrot Party), indemnify, and hold harmless the Carrot Parties from and against any and all claims,
          losses, expenses, demands, or liabilities, including attorneys' fees and costs, incurred by the Carrot Parties in connection with any
          claim by a third party arising out of or in any way related to (1) your use of our Services, our Content o rthe Community Content;
          (2) your violation or alleged violation of the Agreement or your violation or alleged violation of any applicable law;
          (3) your infringement or alleged infringement of any intellectual property or other rights of any other person or entity; or
          (4) any dispute between you and a third party, including any provider of Third Party Content. You must not settle any such claim or matter
          without the prior written consent of Carrot. The Carrot parties reserve the right, at their own expenses, to assume the exclusive defense
          and control of any matter subject to Indemnification by you, and you further agree that you will cooperate fully in the defense of any such claims.
        </p>

        <br />

        <p>
          <b>9. Dispute Resolution and Arbitration</b>
          {' '}
          <br />
          {' '}
          <br />

          <b>9.1 General</b>
          {' '}
          <br />
          {' '}
          <br />

          We shall make an effort to settle all dispute amicably. Any dispute rising out of this Agreement which cannot be settled, by mutual
          agreement/negotiation within 1 (one) month shall be refered to mediation at the Lagos Multi-Door Courthouse ("LMDC") and governed
          by the Arbitration and Concillation Act, Cap A10, Laws of the Federal Republic of Nigeria. The arbitrator shall be appointed by both
          of us (we and you), where both of us are unable to agree on the choice of an arbitrator, the choice of arbitration shall be referred
          to the LMDC. The findings of the arbitrator and subsequent award shall be binding on both of us. Each of us shall bear our respective
          costs in cinnections with the Arbitration. The venue for the arbitration shall be Lagos, Nigeria.
          {' '}
          <br />
          {' '}
          <br />

          <b>9.2 Modifications</b>
          {' '}
          <br />
          {' '}
          <br />

          If Carrot makes any future change to this mediation provision (other than a change to Carrot's address for Notice), you may reject the
          change by sending us written notice within 30 days of the change to Carrot's address for Notice, in which case your account with Carrot
          will be immediately terminated but this mediation provision, as in effect immediately prior to the amendments you reject, will survive.
          {' '}
          <br />
          {' '}
          <br />

          <b>9.3 Enforceability</b>
          {' '}
          <br />
          {' '}
          <br />

          If any provision of this Section 10 is unlawful, void, or unenforceable for any reason, then that provision will be given effect to the
          greatest extent possible and the remaining provisions will remain in full force and effect.
        </p>

        <br />

        <p>
          <b>10. Deactivation and Termination</b>
          {' '}
          <br />
          {' '}
          <br />

          <b>10.1 Termination of Agreement; Surving Terms.</b>
          {' '}
          <br />
          {' '}
          <br />

          The Agreement is effective until your member account, if applicable, is deactivated by either you or Carrot and you discontinue all use of ou r
          Services. Notwithstanding the foregoing, any section of the Agreement that by its nature is intended to survive termination of the Agreement
          on your use or our Services or our Content will survive such termination.
          {' '}
          <br />
          {' '}
          <br />

          <b>10.2 Deactivating Your Member Account</b>
          {' '}
          <br />
          {' '}
          <br />

          If you decide you want to deactivate your account you can do so by sending us a mail at
          {' '}
          <a href="mailto:support@carrotcredit.com">carrotcredit.</a>
        </p>

        <br />

        <p>
          <b>11. Miscellaneous</b>
          {' '}
          <br />
          {' '}
          <br />

          <b>11.1 Governing Law</b>
          {' '}
          <br />
          {' '}
          <br />

          The Agreement is governed by the laws of the Federal Republic of Nigeria.
          {' '}
          <br />
          {' '}
          <br />

          <b>11.2 Entire Agreement</b>
          {' '}
          <br />
          {' '}
          <br />

          The terms of the Agreement (including the Privacy Notice and any Additional Terms) constitute the entire and exclusive
          agreement between Carrot and you in connection with your use of our Services or our Content.
          {' '}
          <br />
          {' '}
          <br />

          <b>11.3 Changes to the Agreement</b>
          {' '}
          <br />
          {' '}
          <br />

          We may modify this Agreement at any time. We will post such modifications on this page. If a change materally modifies
          your rights or obligations, you may need to accept the modified terms to continue to use our Services. Material
          modifications will take effect when you accept the modified terms; immaterial modifications will take effect when published.
          By continuing to use our Services following any modification to these terms, our Privacy Notice, or any Additional Terms, you
          are agreeing to the Agreement as modified.
          {' '}
          <br />
          {' '}
          <br />

          <b>11.4 Waiver Only in Writing</b>
          {' '}
          <br />
          {' '}
          <br />

          Carrot's failure to ensure any of its rights or act concerning a breach by you or others of the Agreement does not constitute
          a waiver of any rights and will not limit Carrot's rights concerning that breach or any subsequent breaches. No waiver by Carrot
          or any of the provisions in the Agreement will be of any force or effect unless made in writing and signed by a duly authorized
          officer of Carrot.
          {' '}
          <br />
          {' '}
          <br />

          <b>11.5 Assignment</b>
          {' '}
          <br />
          {' '}
          <br />

          Carrot may assign the Agreement to any party at any time without notice to you. You may not assign your rights under the Agreement,
          by operation of law or otherwise, without our consent.
          {' '}
          <br />
          {' '}
          <br />

          <b>11.6 Severability</b>
          {' '}
          <br />
          {' '}
          <br />

          If any provision of the Agreement is unlawful, void, or unenforceable for any reason, then that provision will be given effect
          to the greatest extent possible and the remaining provisions will remain in full force and effect.
          {' '}
          <b>
            Nothing in the Agreement shall effect any non-waivable statutory rights or remedies that apply to you.
          </b>
          {' '}
          <br />
          {' '}
          <br />

          <b>11.7 Interpretation</b>
          {' '}
          <br />
          {' '}
          <br />

          The title and annotations contained in these Terms are inserted only as a matter of convenience and have no legal
          or contractual effect. The Agreement will not be construed against Carrot because we drafted it.
        </p>
      </div>
    </div>
  );
}

export default TandC;

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from '../components/shards-react/components';

import MainNavbar from '../components/layout/MainNavbar/MainNavbar';
import MainSidebar from '../components/layout/MainSidebar/MainSidebar';

const DefaultLayout = props => {
  const { children, noNavbar, onSearchInputChange } = props;
  const func4 = val => {
    onSearchInputChange(val);
  };

  return (
    <Container fluid>
      <Row>
        <MainSidebar />
        <Col
          className="main-content p-0"
          lg={{ offset: 2, size: 10 }}
          md={{ offset: 3, size: 9 }}
          sm="12"
          tag="main"
        >
          {!noNavbar && <MainNavbar {...props} func3={func4} />}
          {children}
        </Col>
      </Row>
    </Container>
  );
};

DefaultLayout.propTypes = {

  /**
   * Whether to display the footer, or not.
   */
  // noFooter: PropTypes.bool,

  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  // noFooter: false,
  noNavbar: false,
};

export default DefaultLayout;

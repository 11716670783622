/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Card, CardHeader, CardBody, Button
} from '../shards-react/components';

import RangeDatePicker from '../common/RangeDatePicker';
import Chart from '../../utils/chart';

class UsersOverview extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const chartOptions = {
      ...{
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3,
          },
          point: {
            radius: 0,
          },
        },
        hover: {
          intersect: false,
          mode: 'nearest',
        },
        legend: {
          position: 'top',
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                callback(tick, index) {
                  // Jump every 7 values on the X axis labels to avoid clutter.
                  return index % 7 !== 0 ? '' : tick;
                },
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback(tick) {
                  if (tick === 0) {
                    return tick;
                  }
                  // Format the amounts using Ks for thousands.
                  return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                },
                suggestedMax: 45,
              },
            },
          ],
        },
        tooltips: {
          custom: false,
          intersect: false,
          mode: 'nearest',
        },
      },
      ...this.props.chartOptions,
    };

    const BlogUsersOverview = new Chart(this.canvasRef.current, {
      data: this.props.chartData,
      options: chartOptions,
      type: 'LineWithLine',
    });

    // They can still be triggered on hover.
    const buoMeta = BlogUsersOverview.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[
      this.props.chartData.datasets[0].data.length - 1
    ]._model.radius = 0;

    // Render the chart.
    BlogUsersOverview.render();
  }

  render() {
    const { title, onDateChange } = this.props;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="border-bottom py-2 bg-light">
            <Col sm="6" className="d-flex mb-2 mb-sm-0">
              <RangeDatePicker onDateChange={onDateChange} />
            </Col>
            <Col>
              <Button
                size="sm"
                className="d-flex btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0"
              >
                View Full Report &rarr;
              </Button>
            </Col>
          </Row>
          <canvas
            height="120"
            ref={this.canvasRef}
            style={{ maxWidth: '100% !important' }}
          />
        </CardBody>
      </Card>
    );
  }
}

UsersOverview.propTypes = {

  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,

  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,

  /**
   * The component's title.
   */
  title: PropTypes.string,
};

UsersOverview.defaultProps = {
  chartData: {
    datasets: [
      {
        backgroundColor: 'rgba(0,123,255,0.1)',
        borderColor: 'rgba(0,123,255,1)',
        borderWidth: 1.5,
        data: [
          0,
        ],
        fill: 'start',
        label: 'Current Month',
        pointBackgroundColor: '#ffffff',
        pointHoverBackgroundColor: 'rgb(0,123,255)',
        pointHoverRadius: 3,
        pointRadius: 0,
      },
      {
        backgroundColor: 'rgba(255,65,105,0.1)',
        borderColor: 'rgba(255,65,105,1)',
        borderDash: [3, 3],
        borderWidth: 1,
        data: [
          0,
        ],
        fill: 'start',
        label: 'Past Month',
        pointBackgroundColor: '#ffffff',
        pointBorderColor: 'rgba(255,65,105,1)',
        pointHoverBackgroundColor: 'rgba(255,65,105,1)',
        pointHoverRadius: 2,
        pointRadius: 0,
      },
    ],
    labels: Array.from(new Array(30), (_, i) => (i === 0 ? 1 : i)),
  },
  title: 'Users Overview',
};

export default UsersOverview;

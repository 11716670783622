import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Navbar } from '../../shards-react/components';

import NavbarNav from './NavbarNav/NavbarNav';
import UserActions from './NavbarNav/UserActions';
import NavbarSearch from './NavbarSearch';
import '../../../styles/CustomStyle.css';

const MainNavbar = props => {
  const { stickyTop, func3 } = props;
  const classes = classNames(
    'main-navbar',
    'bg-white',
    stickyTop && 'sticky-top'
  );

  const func2 = val => {
    func3(val);
  };

  return (
    <div className={classes}>
      <Container className="p-0">
        <Navbar type="light" className="p-0">
          <NavbarSearch {...props} func1={func2} />
          <NavbarNav />
          <Navbar type="light" className="p-0">
            {/* <Notifications /> */}
            <UserActions />
          </Navbar>
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  // layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;

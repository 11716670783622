import React, { useState } from 'react';
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from '../../shards-react/components';
import '../../../routes/General.css';

const NavbarSearch = props => {
  const { func1 } = props;

  const [searchInput, setSearchInput] = useState('');

  const clearSearchValue = () => {
    setSearchInput('');
  };

  const handleSearchInputChange = event => {
    func1(event.target.value);
    setSearchInput(event.target.value);
  };

  return (
    <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex formClass">
      <InputGroup seamless className="ml-3">
        <InputGroupAddon type="prepend" className="iconClass search">
          <InputGroupText>
            <i className="material-icons" style={{ color: '#8add66' }}>search</i>
          </InputGroupText>
        </InputGroupAddon>
        <FormInput
          className="navbar-search m-1"
          placeholder="Search"
          value={searchInput}
          onChange={handleSearchInputChange}
        />
        <InputGroupAddon type="append" className="iconClass times">
          <InputGroupText
            onClick={clearSearchValue}
            style={{ color: '#818EA3', fontSize: '10px' }}
          >
            <i className="fas fa-times" />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </Form>
  );
};

export default NavbarSearch;

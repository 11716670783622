/* eslint-disable react/forbid-prop-types */
import React, {
  useState, useEffect, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from '../components/shards-react/components';
import SmallStats from '../components/common/SmallStats';
import UsersOverview from '../components/blog/UsersOverview';
import * as Helpers from '../helpers';
// Layout Types
import { DefaultLayout } from '../layouts';
import '../styles/CustomStyle.css';

const today = new Date();
const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
const yesterdayMonth = yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : `0${yesterday.getMonth() + 1}`;
const tomorrowMonth = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : `0${tomorrow.getMonth() + 1}`;
const yesterdayDay = yesterday.getDate() > 9 ? yesterday.getDate() : `0${yesterday.getDate()}`;
const tomorrowDay = tomorrow.getDate() > 9 ? tomorrow.getDate() : `0${tomorrow.getDate()}`;
const toDate = `${tomorrow.getFullYear()}/${tomorrowMonth}/${tomorrowDay}`;
const fromDate = `${yesterday.getFullYear()}/${yesterdayMonth}/${yesterdayDay}`;

Helpers.token.set(toDate, 'date:to');
Helpers.token.set(fromDate, 'date:from');

const statArray = ['user', 'loan'];

const graphData = {
  loan: {
    dateKey: [],
    total: [],
  },
  repayment: {
    dateKey: [],
    total: [],
  },
  user: {
    dateKey: [],
    total: [],
  },
};

const Dashboard = props => {
  const {
    getAll,
    clearAll,
    smallStats,
  } = props;

  const page = 0;
  const rowsPerPage = 100;
  const search = null;
  const [loanTotal, setLoanTotal] = useState(0);
  const [userTotal, setUserTotal] = useState(0);

  const getData = useCallback((clear = true) => {
    if (clear) {
      clearAll();
    }

    statArray.forEach(stat => {
      setTimeout(() => {
        getAll(
          stat,
          rowsPerPage,
          page,
          search ? null : Helpers.token.get('date:from'),
          search ? null : Helpers.token.get('date:to'),
          search,
          res => {
            if (stat === 'user') {
              setUserTotal(res.data.all.length);
            } else {
              setLoanTotal(res.data.all.length);
            }
          },
          () => {}
        );
      }, 1000);
    });
  }, [clearAll, getAll]);

  useEffect(() => {
    getData();
  }, [getData]);

  const setDate = useCallback(date => {
    Helpers.token.set(date.to, 'date:to');
    Helpers.token.set(date.from, 'date:from');
    getData();
  }, [getData]);

  return (
    <DefaultLayout onSearchInputChange={() => {}}>
      <Container fluid className="main-content-container px-4">

        <Row noGutters className="page-header py-5" />

        {/* Small Stats Blocks */}
        <Row>
          <Col className="col-lg-6 mb-4" key={1} {...smallStats.attrs}>
            <SmallStats
              id={`small-stats-${1}`}
              variation="1"
              label="user"
              value={userTotal}
            />
          </Col>
          <Col className="col-lg-6 mb-4" key={2} {...smallStats.attrs}>
            <SmallStats
              id={`small-stats-${2}`}
              variation="1"
              label="loan"
              value={loanTotal}
            />
          </Col>
        </Row>

        <Row>
          {/* Users Overview */}
          <Col lg="12" md="12" sm="12" className="mb-4">
            <UsersOverview onDateChange={setDate} graphData={graphData} />
          </Col>
        </Row>
      </Container>
    </DefaultLayout>

  );
};

Dashboard.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array,
};

Dashboard.defaultProps = {
  smallStats: {
    attrs: { lg: '3', md: '6', sm: '6' },
    label: 'Total',
    value: 0,
  },
};

export default Dashboard;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import loading from '../assets/images/loading.gif';
import spinner from '../assets/images/spinner.gif';

class Loading extends Component {
  render() {
    const { size } = this.props;
    if (size === 'big') {
      return (
        <div id="loading"><img src={loading} alt="Loading" /></div>
      );
    }
    return <img id="spinner" src={spinner} alt="Loading" {...this.props} />;
  }
}

Loading.props = {
  size: PropTypes.string.isRequired,
};

export default Loading;
